import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Paper, Typography, Button, Link as MUILink } from "@material-ui/core";
import "./Login.css";
import { connect } from "react-redux";
import qs from "qs";
import HubspotLogo from "../../assets/hubspot_logo.png";
import { loginUser } from "../../store/actions";
import Layout from "../../components/Layout/Layout";

class Login extends Component {
  constructor(props) {
    super(props);

    const { history, dispatch } = this.props;
    const { location } = this.props;
    const { token } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (token) {
      localStorage.setItem("accessToken", token);
      dispatch(loginUser(token));
      history.push("/home");
    }
  }

  render() {
    return (
      <Layout>
        <Paper variant="elevation" className="login-container">
          <Typography variant="h3" color="secondary" gutterBottom>
            Welcome
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please Sign in Below
          </Typography>
          <MUILink href={`${process.env.REACT_APP_LOGIN_IN_LINK}`}>
            <Button variant="outlined" color="secondary">
              <img
                style={{ objectFit: "contain", width: "30px", height: "30px" }}
                src={HubspotLogo}
                alt="hubspot-logo"
              />
              Sign In/Sign Up
            </Button>
          </MUILink>
        </Paper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(Login));
