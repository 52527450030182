import React, { Component } from "react";
import "./Layout.css";
import { Menu, MenuItem, Button, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Layout extends Component {
  constructor() {
    super();

    this.state = {
      showAccountMenu: false,
    };
  }

  handleAccountOpen(e) {
    this.setState({
      showAccountMenu: true,
      accountMenuAnchorElement: e.target,
    });
  }

  handleAccountClose() {
    this.setState({ showAccountMenu: false });
  }

  handleLogOut() {
    this.handleAccountClose();
    localStorage.removeItem("accessToken");

    const { history } = this.props;
    history.push("/login");
  }

  render() {
    const { history } = this.props;
    const { showAccountMenu, accountMenuAnchorElement } = this.state;
    const { children } = this.props;
    const currentDate = new Date();

    return (
      <div className="layout-wrapper">
        <header className="layout-header">
          <div className="layout-header-inner">
            <div className="layout-header-logo" />
            <div className="menu-container">
              <Button
                aria-controls="account-button"
                aria-haspopup="true"
                onMouseEnter={(e) => this.handleAccountOpen(e)}
                onMouseLeave={(e) => this.handleAccountOpen(e)}
              >
                Account
              </Button>
              <Menu
                className="account_menu"
                anchorEl={accountMenuAnchorElement}
                keepMounted
                open={showAccountMenu}
                onClose={() => {
                  this.setState({ showAccountMenu: false });
                }}
              >
                <MenuItem onClick={() => history.push("/home")}>Home</MenuItem>
                <MenuItem onClick={() => history.push("/account")}>
                  Account
                </MenuItem>
                <MenuItem onClick={() => this.handleLogOut()}>Log Out</MenuItem>
              </Menu>
            </div>
          </div>
        </header>
        <div className="layout-content">
          <div className="layout-content-inner">{children}</div>
          <div className="clearfooter" />
        </div>
        <div className="layout-footer">
          <div className="layout-footer-inner">
            <Typography variant="body1" color="inherit">
              &#169; {currentDate.getFullYear()}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(Layout));
