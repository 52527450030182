/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import "./EmbeddedForm.css";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";

class EmbeddedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      formError: "",
      dealPipelines: [],
    };
  }

  async componentDidMount() {
    try {
      const { match } = this.props;
      const { ref } = match.params;
      const pipelineResponse = await axios.get(
        "/dev/embedded/hubspot-deal-pipelines",
        { params: { ref } }
      );

      this.setState({ dealPipelines: pipelineResponse.data });
    } catch (e) {
      console.log(e);
    }
  }

  async updateDeal() {
    const { match } = this.props;
    const { ref, dealID } = match.params;
    const { formData } = this.state;

    const newForm = formData;

    if (newForm.pipeline) {
      newForm.pipeline.value = newForm.pipeline.value.pipelineId;
    }

    if (formData.dealstage) {
      newForm.dealstage.value = newForm.dealstage.value.stageId;
    }

    try {
      await axios.post(
        "/dev/embedded/update-deal",
        { data: JSON.stringify(newForm) },
        {
          params: {
            ref,
            dealID,
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async createDeal() {
    const { match } = this.props;
    const { ref } = match.params;
    const { formData } = this.state;

    const newForm = formData;

    if (newForm.pipeline) {
      newForm.pipeline.value = newForm.pipeline.value.pipelineId;
    }

    if (formData.dealstage) {
      newForm.dealstage.value = newForm.dealstage.value.stageId;
    }

    try {
      await axios.post(
        "/dev/embedded/create-deal",
        { data: JSON.stringify(newForm) },
        {
          params: {
            ref,
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { match } = this.props;
    const { data, formType, dealID } = match.params;
    const { formData, formError, dealPipelines } = this.state;

    const decodedData = decodeURIComponent(data);
    const parsedData = JSON.parse(decodedData);

    const currentDate = new Date();

    let currentStage;
    let stages;

    if (dealID && dealID != null) {
      if (
        "pipeline" in formData &&
        formData.pipeline.value &&
        formData.pipeline.value.stages
      ) {
        stages = formData.pipeline.value.stages;
        currentStage = stages.find((element) => element.displayOrder === 0);
      } else if (dealPipelines.results) {
        stages = dealPipelines.results.find(
          (element) => element.default === true
        ).stages;
        currentStage = stages.find((element) => element.displayOrder === 0);
      }
    }

    return (
      <form className="embedded-form-wrapper">
        <Paper variant="outlined" className="embedded-form-inner">
          <Typography gutterBottom style={{ color: "red" }}>
            {formError}
          </Typography>
          {Object.keys(parsedData).map((key) => (
            <div>
              {parsedData[key].hubProperty === "createdate" ||
              parsedData[key].hubProperty === "closedate" ||
              parsedData[key].hubProperty === "hs_lastmodifieddate"
                ? [
                    <InputLabel>{parsedData[key].label}</InputLabel>,
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        fullWidth
                        onLoad={(e) => {
                          if (!formData[key]) {
                            formData[key] = {};
                          }
                          formData[key].value = e.target.value;
                          this.setState({ formData });
                        }}
                        value={
                          formData[key] && formData[key].value
                            ? new Date(formData[key].value)
                            : currentDate
                        }
                        onChange={(val) => {
                          if (!formData[key]) {
                            formData[key] = {};
                          }
                          formData[key].value = val.getTime();
                          this.setState({ formData });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>,
                  ]
                : parsedData[key].hubProperty === "amount" ||
                  parsedData[key].hubProperty === "amount_in_home_currency" ||
                  parsedData[key].hubProperty === "amount" ||
                  parsedData[key].hubProperty === "hs_closed_amount" ||
                  parsedData[key].hubProperty ===
                    "hs_closed_amount_in_home_currency" ||
                  parsedData[key].hubProperty === "hs_projected_amount" ||
                  parsedData[key].hubProperty ===
                    "hs_projected_amount_in_home_currency"
                ? [
                    <InputLabel>{parsedData[key].label}</InputLabel>,
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="decimal"
                      inputMode="decimal"
                      onLoad={(e) => {
                        if (!formData[key]) {
                          formData[key] = {};
                        }
                        formData[key].value = e.target.value;
                        this.setState({ formData });
                      }}
                      onChange={(e) => {
                        const regex = new RegExp(/^\d*\.?\d*$/);
                        if (
                          e.target.value.match(regex) &&
                          (parsedData[key].hubProperty === "amount" ||
                            parsedData[key].hubProperty ===
                              "amount_in_home_currency" ||
                            parsedData[key].hubProperty === "days_to_close" ||
                            parsedData[key].hubProperty ===
                              "hs_closed_amount" ||
                            parsedData[key].hubProperty ===
                              "hs_closed_amount_in_home_currency" ||
                            parsedData[key].hubProperty ===
                              "hs_projected_amount" ||
                            parsedData[key].hubProperty ===
                              "hs_projected_amount_in_home_currency")
                        ) {
                          this.setState({ formError: "" });
                        } else {
                          this.setState({
                            formError: "Please enter a valid amount",
                          });
                        }

                        if (!formData[key]) {
                          formData[key] = {};
                        }
                        formData[key].value = e.target.value;
                        this.setState({ formData });
                      }}
                    />,
                  ]
                : parsedData[key].hubProperty === "pipeline" ||
                  parsedData[key].hubProperty === "dealstage"
                ? [
                    <InputLabel>{parsedData[key].label}</InputLabel>,
                    <Select
                      label={parsedData[key].label}
                      variant="outlined"
                      fullWidth
                      value={
                        formData[key] && formData[key].value
                          ? formData[key].value
                          : parsedData[key].hubProperty === "pipeline"
                          ? "default"
                          : currentStage
                      }
                      onChange={(e) => {
                        console.log(formData);
                        if (!formData[key]) {
                          formData[key] = {};
                        }
                        formData[key].value = e.target.value;
                        console.log(formData);
                        this.setState({ formData });
                      }}
                    >
                      {parsedData[key].hubProperty === "pipeline" &&
                      dealPipelines.results
                        ? dealPipelines.results.map((pipeline) => (
                            <MenuItem
                              key={pipeline.pipelineId}
                              value={pipeline}
                            >
                              {`${pipeline.label}`}
                            </MenuItem>
                          ))
                        : stages
                        ? stages.map((thisStage) => (
                            <MenuItem key={thisStage.stageId} value={thisStage}>
                              {thisStage.label}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>,
                  ]
                : [
                    <InputLabel>{parsedData[key].label}</InputLabel>,
                    <TextField
                      variant="outlined"
                      fullWidth
                      onLoad={(e) => {
                        if (!formData[key]) {
                          formData[key] = {};
                        }
                        formData[key].value = e.target.value;
                        this.setState({ formData });
                      }}
                      onChange={(e) => {
                        if (!formData[key]) {
                          formData[key] = {};
                        }
                        formData[key].value = e.target.value;
                        this.setState({ formData });
                      }}
                    />,
                  ]}
              <br />
              <br />
            </div>
          ))}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (formError === "") {
                if (formType === "create") {
                  this.createDeal();
                } else {
                  this.updateDeal();
                }
              }
            }}
          >
            Submit
          </Button>
        </Paper>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(EmbeddedForm));
