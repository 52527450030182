import axios from "axios";
import JwtDecode from "jwt-decode";
// TYPES
export const actionTypes = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
};

// Action creators
export function loginUser(token) {
  return async function login(dispatch) {
    if (!token) return;
    const tokenData = await JwtDecode(token);
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    const userData = tokenData.user;

    dispatch({ type: actionTypes.LOGIN_USER, userData });
  };
}

export function logoutUser() {
  return {
    type: actionTypes.LOGOUT_USER,
  };
}
