/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable class-methods-use-this */
import "./FormCreation.css";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { v4 as uuidv4 } from "uuid";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import Axios from "axios";
import Layout from "../../components/Layout/Layout";

class FormCreation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDrag: null,
      draggedItems: {},
      dealPipelines: [],
      reactScripts: `<script src="https://unpkg.com/react@16.4.1/umd/react.production.min.js"></script>\n<script src="https://unpkg.com/react-dom@16.4.1/umd/react-dom.production.min.js"></script>`,
    };
  }

  async componentDidMount() {
    try {
      const pipelineResponse = await Axios.get(
        "/dev/api/hubdeals/hubspot-deal-pipelines"
      );

      console.log(pipelineResponse.data);
      this.setState({ dealPipelines: pipelineResponse.data });
    } catch (e) {
      console.log(e);
    }
  }

  onDrop(e) {
    e.preventDefault();

    const { currentDrag, draggedItems } = this.state;

    draggedItems[currentDrag] = {
      hubProperty: currentDrag,
      label: currentDrag,
    };

    this.setState({ draggedItems });
  }

  onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onDragStart(e) {
    const property = e.target.id.split("-")[0];
    this.setState({ currentDrag: property });
  }

  onCopy(e, str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  render() {
    const { draggedItems, reactScripts, dealPipelines } = this.state;
    const { match, userData } = this.props;
    const { deal, dealID } = match.params;
    const parsedDeal = JSON.parse(deal);
    const defaultProperties = [
      "amount",
      "amount_in_home_currency",
      "hs_closed_amount",
      "hs_closed_amount_in_home_currency",
      "hs_projected_amount",
      "hs_projected_amount_in_home_currency",
      "days_to_close",
      "closedate",
      "createdate",
      "dealname",
      "dealstage",
      "hs_lastmodifieddate",
      "hs_object_id",
      "pipeline",
    ];

    const optionTypes =
      dealID === "null"
        ? defaultProperties
        : Object.keys(parsedDeal.properties);

    const draggedItemValues = Object.values(draggedItems);
    const currentDate = new Date();

    let currentStage;
    let stages;

    if (deal && dealID && dealID != null) {
      if ("pipeline" in draggedItems) {
        if (draggedItems.pipeline.value && draggedItems.pipeline.value.stages) {
          stages = draggedItems.pipeline.value.stages;
          currentStage = stages.find((element) => element.displayOrder === 0);
        }
      } else if (dealPipelines.results) {
        const defaultResult = dealPipelines.results.find(
          (element) => element.default === true
        );
        if (defaultResult) {
          stages = defaultResult.stages;
          currentStage = stages.find((element) => element.displayOrder === 0);
        }
      }
    }

    return (
      <Layout>
        <div className="form-builder-wrapper">
          <div className="form-builder-inner">
            <Paper variant="elevation" className="form-builder-canvas">
              <div className="form-builder-canvas-components">
                {optionTypes.map((component) => (
                  <Paper
                    variant="elevation"
                    draggable
                    className="form-builder-canvas-component"
                    onDragStart={(e) => this.onDragStart(e)}
                    id={`${component}-${uuidv4()}`}
                  >
                    <Typography variant="body1">{component}</Typography>
                  </Paper>
                ))}
              </div>
              <div
                className="form-builder-canvas-drop-area"
                onDrop={(e) => this.onDrop(e)}
                onDragOver={(e) => this.onDragOver(e)}
              >
                {draggedItemValues.length === 0 ? (
                  <div className="empty-drag-area">
                    <Typography variant="h3">
                      Drag and drop items here to create a form
                    </Typography>
                  </div>
                ) : (
                  draggedItemValues.map((item) => (
                    <div>
                      <Button
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => {
                          delete draggedItems[item.hubProperty];
                          this.setState({ draggedItems });
                        }}
                        variant="outlined"
                      >
                        Remove
                      </Button>
                      {item.hubProperty === "createdate" ||
                      item.hubProperty === "closedate" ||
                      item.hubProperty === "hs_lastmodifieddate"
                        ? [
                            <TextField
                              value={item.label}
                              InputProps={{ disableUnderline: true }}
                              onChange={(e) => {
                                draggedItems[item.hubProperty].label =
                                  e.target.value;
                                this.setState({ draggedItems });
                              }}
                            />,
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                fullWidth
                                value={
                                  item.value
                                    ? new Date(item.value)
                                    : currentDate
                                }
                                onChange={(val) => {
                                  draggedItems[item.hubProperty].value = val;
                                  this.setState({ draggedItems });
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>,
                          ]
                        : item.hubProperty === "pipeline" ||
                          item.hubProperty === "dealstage"
                        ? [
                            <TextField
                              value={item.label}
                              InputProps={{ disableUnderline: true }}
                              onChange={(e) => {
                                draggedItems[item.hubProperty].label =
                                  e.target.value;
                                this.setState({ draggedItems });
                              }}
                            />,
                            <Select
                              variant="outlined"
                              fullWidth
                              value={
                                item && item.value
                                  ? item.value
                                  : item.hubProperty === "pipeline"
                                  ? "default"
                                  : currentStage
                              }
                              onChange={(e) => {
                                draggedItems[item.hubProperty].value =
                                  e.target.value;
                                this.setState({ draggedItems });
                              }}
                            >
                              {item.hubProperty === "pipeline"
                                ? dealPipelines.results.map((pipeline) => (
                                    <MenuItem value={pipeline}>
                                      {pipeline.label}
                                    </MenuItem>
                                  ))
                                : stages
                                ? stages.map((thisStage) => (
                                    <MenuItem value={thisStage}>
                                      {thisStage.label}
                                    </MenuItem>
                                  ))
                                : ""}
                            </Select>,
                          ]
                        : [
                            <TextField
                              value={item.label}
                              InputProps={{ disableUnderline: true }}
                              onChange={(e) => {
                                draggedItems[item.hubProperty].label =
                                  e.target.value;
                                this.setState({ draggedItems });
                              }}
                            />,
                            <TextField
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                draggedItems[item.hubProperty].value =
                                  e.target.value;
                                this.setState({ draggedItems });
                              }}
                            />,
                          ]}
                      <br />
                      <br />
                    </div>
                  ))
                )}
              </div>
              <div className="embed-code-div-outer">
                <br />
                <Typography variant="h4">How To Embed</Typography>
                <br />
                <Typography variant="h6">
                  Add React scripts to head tag
                </Typography>
                <Paper
                  variant="elevation"
                  color="secondary"
                  classes={{
                    root: {
                      backgroundColor: "grey",
                    },
                  }}
                  className="embed-code-div"
                >
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <IconButton onClick={(e) => this.onCopy(e, reactScripts)}>
                      <AssignmentOutlinedIcon />
                    </IconButton>
                  </div>
                  <Typography variant="body1">
                    {reactScripts.split("\n")[0]}
                    <br />
                    {reactScripts.split("\n")[1]}
                  </Typography>
                </Paper>
                <br />
                <Typography variant="h6">
                  Copy and paste iFrame where necessary
                </Typography>
                <Paper
                  variant="elevation"
                  color="secondary"
                  classes={{
                    root: {
                      backgroundColor: "grey",
                    },
                  }}
                  className="embed-code-div"
                >
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <IconButton
                      onClick={(e) =>
                        this.onCopy(
                          e,
                          `<iframe width="100%" height="100%" src="http://updatedeals.larmahil.com/embedded-form/${
                            dealID === "null" ? "create" : "update"
                          }/${userData.id}/${dealID}/${encodeURIComponent(
                            JSON.stringify(draggedItems)
                          )}"><iframe>`
                        )
                      }
                    >
                      <AssignmentOutlinedIcon />
                    </IconButton>
                  </div>
                  <Typography variant="body1">
                    {`<iframe width="100%" height="100%" src="http://updatedeals.larmahil.com/embedded-form/${
                      dealID === "null" ? "create" : "update"
                    }/${userData.id}/${dealID}/${encodeURIComponent(
                      JSON.stringify(draggedItems)
                    )}"><iframe>`}
                  </Typography>
                </Paper>
                <br />
              </div>
            </Paper>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(FormCreation));
