import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { connect } from "react-redux";
import { loginUser } from "./store/actions";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import FormCreation from "./pages/FormCreation/FormCreation";
import EmbeddedForm from "./pages/EmbeddedForm/EmbeddedForm";
import Account from "./pages/Account/Account";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import PaymentFailure from "./pages/Payment/PaymentFailure";

class App extends Component {
  constructor() {
    super();

    this.state = {
      prefersDarkMode: false,
    };
  }

  async componentDidMount() {
    const script = document.createElement("script");

    script.src = "//js.hs-scripts.com/7831133.js";
    script.id = "hs-script-loader";
    script.type = "text/javascript";
    script.defer = true;
    script.async = true;

    document.body.appendChild(script);

    const { dispatch } = this.props;

    const { accessToken } = localStorage;
    if (accessToken) {
      try {
        await dispatch(loginUser(accessToken));
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const { prefersDarkMode } = this.state;
    const theme = createMuiTheme({
      typography: {
        fontFamily: "'Roboto', sans-serif",
      },
      palette: {
        primary: {
          main: prefersDarkMode ? "#0d3c55" : "#0d3c55",
          light: prefersDarkMode ? "#1395ba" : "#1395ba",
        },
        secondary: {
          main: prefersDarkMode ? "#f16c20" : "#f16c20",
          light: prefersDarkMode ? "#ff5d00" : "#ff5d00",
        },
        textSecondary: {
          main: "white",
          light: "white",
        },
        type: "light",
        tonalOffset: {
          dark: 0.1,
        },
      },
    });

    // eslint-disable-next-line no-shadow
    const AuthRoute = ({ component: Component, path, exact = false }) => {
      const { accessToken } = localStorage;
      if (!accessToken) {
        return <Redirect to="/login" />;
      }

      return <Route exact={exact} path={path} render={() => <Component />} />;
    };

    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path={["/", "/login"]} exact component={Login} />
          <Route
            path="/embedded-form/:formType/:ref/:dealID/:data"
            exact
            component={EmbeddedForm}
          />

          <AuthRoute path="/home" exact component={Home} />
          <AuthRoute path="/account" exact component={Account} />
          <AuthRoute path="/payment-success" exact component={PaymentSuccess} />
          <AuthRoute path="/payment-failure" exact component={PaymentFailure} />
          <AuthRoute
            path="/form-creation/:dealID/:deal"
            exact
            component={FormCreation}
          />
          <Redirect to="/404" />
        </Switch>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps)(withRouter(App));
