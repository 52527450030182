import "./Home.css";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import Layout from "../../components/Layout/Layout";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paging: null,
      limit: 20,
      accountDetails: {},
      showNotSubscribedDialog: false,
    };
  }

  async componentDidMount() {
    try {
      const { limit } = this.state;

      const dealsResponse = await axios.get("/dev/api/hubdeals/get-deals", {
        params: {
          limit,
        },
      });

      console.log(dealsResponse.data);
      this.setState({
        deals: dealsResponse.data.results,
        paging: dealsResponse.data.paging,
      });
    } catch (e) {
      console.error(e);
    }

    try {
      const userDetailsResponse = await axios.get(
        "/dev/api/user/get-user-details"
      );

      this.setState({ accountDetails: userDetailsResponse.data });
    } catch (e) {
      console.log(e);
    }
  }

  async handlePageChange(direction) {
    try {
      const { limit, paging } = this.state;

      const dealsResponse = await axios.get("/dev/api/hubdeals/get-deals", {
        params: {
          limit,
          after: direction === "prev" ? paging.next.after : paging.prev.before,
        },
      });

      console.log(dealsResponse.data);
      this.setState({
        deals: dealsResponse.data.results,
        paging: dealsResponse.data.paging,
      });
    } catch (e) {
      console.error(e);
    }
  }

  displayNonSubscribed() {
    this.setState({ showNotSubscribedDialog: true });
  }

  render() {
    const { deals, paging } = this.state;
    const { history } = this.props;
    const { accountDetails } = this.state;
    const { showNotSubscribedDialog } = this.state;

    let isSubscribed = false;
    let endDate;
    const currentDate = moment.now();

    if (accountDetails && accountDetails.currentPayment) {
      endDate = moment.unix(accountDetails.currentPayment.current_period_end);
      isSubscribed = moment(currentDate).isBefore(endDate);
    }

    return (
      <Layout>
        <Dialog
          open={showNotSubscribedDialog}
          onClose={() => this.setState({ showNotSubscribedDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You need to be subscribed to access this feature.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ showNotSubscribedDialog: false })}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="home-wrapper">
          <div className="home-wrapper-inner">
            <div className="home-options">
              <Paper variant="elevation" className="home-deals-table">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    isSubscribed === true
                      ? history.push(`/form-creation/${null}/${null}`)
                      : this.displayNonSubscribed()
                  }
                  style={{ float: "right" }}
                >
                  Create Deal
                </Button>
                <TextField
                  fullWidth
                  label="Search for Deals"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell color="secondary">Deal ID</TableCell>
                      <TableCell color="secondary">Deal Name</TableCell>
                      <TableCell color="secondary">Deal Stage</TableCell>
                      <TableCell color="secondary">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deals
                      ? deals.map(
                          (
                            { id, properties: { dealname, dealstage } },
                            index
                          ) => (
                            <TableRow>
                              <TableCell>{id}</TableCell>
                              <TableCell>{dealname}</TableCell>
                              <TableCell>{dealstage}</TableCell>
                              <TableCell>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() =>
                                    isSubscribed === true
                                      ? history.push(
                                          `/form-creation/${id}/${JSON.stringify(
                                            deals[index]
                                          )}`
                                        )
                                      : this.displayNonSubscribed()
                                  }
                                >
                                  Create Embeddable Form
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      : ""}
                  </TableBody>
                </Table>
                <br />
                <div className="pagination-div">
                  <span>
                    <Button
                      variant="outlined"
                      disabled={
                        !paging ||
                        paging === null ||
                        !paging.prev ||
                        paging.prev === null ||
                        !paging.prev.before
                      }
                      color="secondary"
                      onClick={() => this.handlePageChange("prev")}
                    >
                      Prev
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={
                        !paging || paging === null || !paging.next.after
                      }
                      color="secondary"
                      onClick={() => this.handlePageChange("next")}
                    >
                      Next
                    </Button>
                  </span>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(Home));
