import React from "react";
import "./Payment.css";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Paper, Typography, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Layout from "../../components/Layout/Layout";

function PaymentSuccess() {
  const history = useHistory();

  return (
    <Layout>
      <div className="payment_wrapper">
        <Paper variant="outlined" className="payment_wrapper_inner">
          <Typography variant="h5">Payment Successful</Typography>
          <div>
            <CheckCircleIcon
              fontSize="large"
              style={{ width: "300px", height: "300px" }}
              className="payment_icon"
              color="primary"
            />
          </div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.push("/home")}
          >
            Back to Home
          </Button>
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.push("/account")}
          >
            Back to Account
          </Button>
        </Paper>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentSuccess));
