import { actionTypes } from "./actions";

const initialState = {
  userData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER: {
      const { userData } = action;
      return {
        ...state,
        userData,
      };
    }
    case actionTypes.LOGOUT_USER: {
      localStorage.removeItem("accessToken");
      return { ...state, userData: null };
    }
    case actionTypes.UPDATE_USER: {
      const { userData } = action;
      return {
        ...state,
        userData,
      };
    }
    case actionTypes.GET_PROJECT_DATA: {
      return this.state.projects;
    }
    case actionTypes.GET_QUOTE_DATA: {
      return this.state.quotes;
    }
    default:
      return state;
  }
};

export default reducer;
