import React, { Component } from "react";
import "./Account.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Paper, Typography, Button } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import Layout from "../../components/Layout/Layout";

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

async function handleSubscribe() {
  try {
    const sessionResponse = await axios.get("/dev/api/payment/create-session");

    await stripe.redirectToCheckout({
      sessionId: sessionResponse.data.id,
    });
  } catch (e) {
    console.log(e);
  }
}

class Account extends Component {
  constructor() {
    super();

    this.state = {
      accountDetails: {},
    };
  }

  async componentDidMount() {
    try {
      const userDetailsResponse = await axios.get(
        "/dev/api/user/get-user-details"
      );

      this.setState({ accountDetails: userDetailsResponse.data });
    } catch (e) {
      console.log(e);
    }
  }

  async cancelSubscription() {
    this.setState();
  }

  render() {
    const { accountDetails } = this.state;

    let endDate;
    let cancelAtPeriodEnd;
    let subscriptionStatus;
    const currentDate = moment.now();

    if (accountDetails && accountDetails.currentPayment) {
      endDate = moment.unix(accountDetails.currentPayment.current_period_end);
      cancelAtPeriodEnd = accountDetails.currentPayment.cancel_at_period_end;
      subscriptionStatus = moment(currentDate).isBefore(endDate)
        ? "Active"
        : "Inactive";
    }

    return (
      <Layout>
        <div className="account_wrapper">
          <Paper
            className="account_wrapper_inner"
            variant="outlined"
            color="secondary"
          >
            <Typography variant="body1" gutterBottom>
              {`Subscription Status: ${subscriptionStatus}`}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`Recurring: ${!cancelAtPeriodEnd}`}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`Subscription End: ${endDate}`}
            </Typography>
            {!subscriptionStatus || subscriptionStatus === "Inactive" ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSubscribe()}
              >
                Subscribe
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.cancelSubscription()}
              >
                Cancel Subscription
              </Button>
            )}
          </Paper>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default withRouter(connect(mapStateToProps)(Account));
